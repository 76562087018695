*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: 100vh;
}

body,
header,
ul,
li,
div {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

ul,
li {
  list-style: none;
}

button {
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  border-radius: 0;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

h1 {
  font-family: "Kanit", sans-serif;
  font-size: clamp(28px, 3vw, 38px);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
