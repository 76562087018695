.container{
  &-gradation {
    &-top {
      margin-bottom: -5px;
    }
    &-bottom {
      
      margin-bottom: -5px;
    }
  }
}

/* レスポンシブ SP */
@media (max-width: 430px) {
  .container{
    &-gradation {
      &-top {
        margin-bottom: -5px;
      }
      &-bottom {
        margin-bottom: -5px;
      }
    }
  }
}
