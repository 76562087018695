.contact {
  background-color: #fff9ec;
  padding: 3rem 2rem 4.5rem;
  text-align: center;
  &-title {
    color: #FF690C;
  }
  &-show {
    opacity: 1;
    animation: fadeIn 1s ease-in-out forwards;
  }
  &-container {
    opacity: 0;
    display: flex;
    &-left {
      padding: 0 5%;
      width: 65%;
    }
    &-right {
      text-align: left;
      margin-top: 2%;
      &-detail {
        display: flex;
        align-items: center;
        &-label {
          padding-right: 1%;
          width: 40%;
        }
      }
      &-sns {
        width: 40%;
        display: flex;
        margin: 2% 0;
        &-icons {
          text-align: center;
          &-icon {
          }
          &-text {
            margin: 0;
            font-size: 75%;
          }
        }
      }
    }
  }
}

/* レスポンシブ SP */
@media (max-width: 430px) {
  .contact-container{
    &-left {
      display: none;
    }
    &-right-sns {
      width: 60%;
    }
  }
}