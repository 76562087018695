.about {
  background-color: #fff9ec;
  padding: 3rem 2rem 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  
  &-title{
    color: #FF690C;
  }
  &-detail{
    opacity: 0;
  }
  &-show {
    opacity: 1;
    animation: fadeIn 1s ease-in-out forwards;
  }
}