.header-menu {
  display: flex;
  justify-content: flex-end;
  padding-right: 2%;
}

.toggleButon {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  z-index: 100;
}

.toggleButon > span {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: black;
}

.line-1 {
  transform: translate(0, -10px);
}

.line-2 {
  transform: translate(0, 10px);
}

.toggleButon[aria-expanded="true"] > .line-1 {
  transform: translate(0, 0) rotate(-45deg);
}

.toggleButon[aria-expanded="true"] > .line-2 {
  transform: translate(0, 0) rotate(45deg);
}