/* レスポンシブ SP */
@media (max-width: 430px) {
  .navigation {
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5rem 2rem;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #ff904bcc;
    width: 100%;
    height: 50%;
    li {
      padding-bottom: 3%;
    }
  }
  
  .navigation[aria-hidden="false"] {
    display: flex;
    z-index: 10;
    text-align: center;
  }
  .top {
    background-color: #FDFDFD;
    padding: 3rem 2rem 2rem;
    text-align: center;
    // display: flex;
    opacity: 0;
    position: relative;
    &-header {
      position: absolute;
      top: 3%;
      right: 3%;
    }
    &-left{
      // position: relative;
      &-top {
        position: absolute;
        top: 56%;
        left: 6%;
        text-align: left;
        z-index: 1;
        width: 90%;
        p {
          margin: 0;
        }
        h1 {
          margin: 0;
        }
      }
      &-bottom {
        display: none;
      }
    }
  
    &-title{
      color: #FF690C;
      text-shadow: 2px 2px 3px rgba(156,156,156,0.6);
      font-size: 35px;
    }
    &-subtitle{
      padding-left: 5%;
      font-size: 100%;
    }
    &-detail{
      &-img {
        width: 100%;
        opacity: 0.7;
        img {
          border-radius: 70% 50% 50% 70% / 50% 50% 70% 60%;
        }
      }
    }
  }
}
/* レスポンシブ PC */
@media (min-width: 430px) {
  .top {
    max-width: 1070px;
    margin: 0 auto;
    width: 100%;
    background-color: #FDFDFD;
    padding: 3rem 2rem 2rem;
    display: flex;
    opacity: 0;
    align-items: center;
    &-header{
      display: none;
    }
    &-left{
      display: flex;
      flex-direction: column;
      // animation: fadeIn 1s ease-in-out forwards;
      &-top {
        text-align: left;
        margin: 5% 0;
        p {
          font-size: clamp(16px, 1.8vw, 23px);
          // font-size: 2vw;
          margin: 0;
        }
      }
      &-bottom {
        width: 30%;
        text-align: left;
        padding-left: 5%;
        margin-top: 3%;
      
        &-text {
          margin: 0;
          font-size: 1.5vw;
          a {
            text-decoration-line: none;
          }
        }
        &-border {
          border: 1px solid #000;
          width: 100%;
          margin: 5% 0;
        }
      }
    }
  
    &-title{
      color: #FF690C;
      font-size: clamp(28px, 6vw, 50px);
      margin: 0;
    }
    &-detail{
      &-img {
        width: 60%;
        img {
          border-radius: 70% 50% 50% 70% / 50% 50% 70% 60%;
        }
      }
    }
  }
}

.animate{
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
