.media {
  background-color: #FDFDFD;
  padding: 3rem 2rem 2rem;
  text-align: center;

  &-title{
    color: #FF690C;
  }
  &-show {
    opacity: 1;
    animation: fadeIn 1s ease-in-out forwards;
  }
  &-table {
    opacity: 0;
  }
  &-detail{
    display: flex;
    justify-content: center;
    &-date {
      text-align: left;
    }
    &-icon {
      border-radius: 12px;
      background-color: #ffe911;
      text-align: center;
      width: 8%;
      margin-left: 1%;
    }
    // &-noIcon {
    //   border-radius: 12px;
    //   background-color: transparent;
    //   color: transparent;
    //   text-align: center;
    //   width: 8%;
    // }
    &-url {
      padding-left: 1%;
    }
  }
}

/* レスポンシブ SP */
@media (max-width: 430px) {
  .media-detail {
    flex-direction: column;
    align-items: center;
    &-url {
      margin-top: 0;
    }
  }
}