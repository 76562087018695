.footer {
  &-container {
    background-color: #FF690C;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 50%;
    margin: 0;
    padding-right: 5%;
  }
}
